import React from "react";

const Banner = () => {
    return (
        <section id="banner">
            <h2 style={{ maxWidth: 900}}>Software und Cloud-Dienste für Schulen</h2>
            <p><small>Diese Seite befindet sich im Aufbau...</small></p>
            <ul className="actions special">
                <li><a href="#one" className="button primary scrolly">Mehr erfahren</a></li>
            </ul>
        </section>
    );
};

export default Banner;
