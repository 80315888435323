/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*Four*/
/*eslint-disable max-len*/
/*eslint-enable max-len*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Banner from "../components/Banner";
import BoxSection from "../components/BoxSection";
import {Link} from "gatsby";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(Banner), "\n", React.createElement(BoxSection, {
    title: "Angebote",
    text: React.createElement(_components.p, null, " In Kürze finden Sie hier Angebote zur Unterstützung der Digitalisierung an Ihrer Schule."),
    variant: "alt",
    extraClasses: "special"
  }, React.createElement("ul", {
    class: "icons-grid"
  }, React.createElement("li", null, React.createElement("span", {
    class: "icon solid major style4 fa-desktop"
  }), React.createElement("h3", null, "Software"), React.createElement("p", null, React.createElement(_components.p, null, React.createElement(Link, {
    href: "https://fehlzeitenerfassung.de"
  }, "Fehlzeitenerfassung"), React.createElement("br"), "\nElektronisches Klassenbuch"))), React.createElement("li", null, React.createElement("span", {
    class: "icon solid major style3 fa-cloud"
  }), React.createElement("h3", null, "Cloud-Dienstleistungen"), React.createElement("p", null, React.createElement(_components.p, null, "Digitales Klassenzimmer (Big-Blue-Button Video-Konferenz),", React.createElement("br"), "\nDateispeicher,", React.createElement("br"), "\nE-Mail,", React.createElement("br"), "\nKalender"))), React.createElement("li", null, React.createElement("span", {
    class: "icon solid major style2 fa-lock"
  }), React.createElement("h3", null, "Ihre Daten unter Ihrer Kontrolle"), React.createElement("p", null, React.createElement(_components.p, null, "Hohes Schutzniveau durch clientseitige Verschlüsselung,", React.createElement("br"), "\nStrikte Achtung der Privatsphäre,", React.createElement("br"), "\nHosting in Deutschland"))), React.createElement("li", null, React.createElement("span", {
    class: "icon solid major style1 fa-user-friends"
  }), React.createElement("h3", null, "Beratung"), React.createElement("p", null, React.createElement(_components.p, null, "Beratung beim Digitalisierungsprozess,", React.createElement("br"), "\nHilfe bei der Aquise von Fördermitteln", React.createElement("br")))))), "\n", "\n", React.createElement("section", {
    id: "four",
    class: "wrapper style3 special"
  }, React.createElement("div", {
    class: "container"
  }, React.createElement("header", {
    class: "major"
  }, React.createElement("h2", null, "Fehlzeitenerfassung"), "\n", React.createElement("p", null, "Mit der Fehlzeitenerfassung können Sie schnell und einfach ", React.createElement("br"), "\ndie Fehlzeiten Ihrer Schüler erheben und analysieren. ", React.createElement("br"), "\nAußerdem können Sie mit der dazugehörigen App automatisch ", React.createElement("br"), "\noffizielle Schreiben erstellen.")), React.createElement("ul", {
    class: "actions special"
  }, React.createElement("li", null, React.createElement("a", {
    href: "https://fehlzeitenerfassung.de/app/#/user/logon+step=register",
    class: "button primary"
  }, "Registrierung")), "\n", React.createElement("li", null, React.createElement("a", {
    href: "https://doc.fehlzeitenerfassung.de/",
    class: "button alt doku"
  }, "Dokumentation"))))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
